<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <Breadcrumb :datas="breadcrumb_data" />
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t("bank_management.list_all") }}
              </h3>
            </div>
            <div class="card-toolbar">
              <!--begin::Dropdown-->
              <DatatableDropdown />
              <!--end::Dropdown-->
              <!--begin::Button-->
              <router-link
                to="/bank-management/new"
                class="btn btn-primary font-weight-bolder"
                v-if="checkPermission('crm.bank.create')"
              >
                <span class="svg-icon svg-icon-md">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <circle fill="#000000" cx="9" cy="15" r="6" />
                      <path
                        d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                    </g>
                  </svg>
                  <!--end::Svg Icon--> </span
                >{{ $t("bank_management.add_new") }}
              </router-link>
              <!--end::Button-->
            </div>
          </div>
          <div class="card-body">
            <Datatable
                ref="datatable"
                :url="datatableUrl"
                :dataUrl="datatableDataUrl"
                @data="loadDatatableData($event)"
            >
                <!-- <template v-slot:head>
                    Table head özelleştirme
                </template> -->
                <template v-slot:body>
                  <tr v-for="(item, index) in datatableData" :key="'data-' + index">
                    <th scope="row">{{ item.id }}</th>
                    <td>{{ item.name }}</td>
                    <td>{{ item.country_id | uppercase }}</td>
                    <td>{{ item.type }}</td>
                    <td>{{ (item.status) ? $t(`service.${item.status}`) : '' }}</td>
                    <td>
                      <router-link
                        v-if="checkPermission('crm.bank.edit')"
                        :to="`/bank-management/update/${item.id}`"
                        class="btn btn-sm btn-clean btn-icon btn-icon-md"
                        :title="$t('commons.edit')"
                      >
                        <i class="la la-pencil"></i>
                      </router-link>
                      <button
                        v-if="checkPermission('crm.bank.destroy')"
                        type="button"
                        @click="deleteBank(item.id)"
                        class="btn btn-sm btn-clean btn-icon btn-icon-md"
                        :title="$t('commons.delete')"
                      >
                        <i class="la la-trash"></i>
                      </button>
                    </td>
                  </tr>
                </template>
            </Datatable>
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Breadcrumb from "@/components/layout/Breadcrumb.vue";
import Swal from "sweetalert2";
import Datatable from "@/components/datatable/Datatable.vue";
import api_old from "@/api/index_old";
import DatatableDropdown from '@/components/datatable/Dropdown.vue'

export default {
  name: "BankList",
  data() {
    return {
      datatableUrl: "",
      datatableDataUrl: "",
      datatableData: [],
    }
  },
  components: {
    Breadcrumb,
    Datatable,
    DatatableDropdown
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.bankManagement.isLoading,
      permissions: (state) => state.customer.permissions,
      lang: (state) => state.global.lang,
    }),
    breadcrumb_data() {
      return {
        title: this.$t("general_management.title"),
        subtitle: [ this.$t("general_management.bank_title") ],
      };
    },
  },
  methods: {
    ...mapActions("bankManagement", ["DESTROY"]),
    deleteBank(id) {
      const vm = this;
      Swal.fire({
        title: this.$t("commons.are_you_sure"),
        text: this.$t("commons.wont_be_able_to_revert"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("commons.yes_delete_it"),
        cancelButtonText: this.$t("commons.cancel"),
      }).then(function (result) {
        if (result.value) {
          vm.DESTROY(id).then(() => {
            vm.initDatatable();
          })
        }
      });
    },
    checkPermission(permission) {
      return this.permissions?.some(item => String(item.name) === String(permission));
    },
    loadDatatableData(data) {
      this.$set(this, "datatableData", data);
    },
    initDatatable() {
      this.$refs.datatable.loadData();
    },
  },
  created() {
    if (this.permissions?.length > 0 && !this.checkPermission("crm.bank.index")) {
      this.$router.push("/no-permission");
    }
    this.datatableUrl = api_old.bank_management_datatable;
    this.datatableDataUrl = api_old.bank_management_datatable_data;
  },
};
</script>
